import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/layout";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import AboutImages from "../components/AboutImages";
export const _frontmatter = {
  "title": "About"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout className="page about-page" mdxType="Layout">
      <Seo title={props.pageContext.frontmatter.title} mdxType="Seo" />
      <div className="col-10 offset-1">
        <h1>{`About Huey`}</h1>
        <AboutImages mdxType="AboutImages" />
      </div>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      